<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CUSTOMER_ORG_TYPES } from './store'
import { ROUTES as CUSTOMER_ORG_ROUTES } from './router'

import CustomerOrganizationTable from './CustomerOrganizationTable'
import CustomerOrganizationCommonFilters from './CustomerOrganizationCommonFilters'
import OrganizationListMixin from '../../../components/organization/mixins/OrganizationListMixin'

export default {
  name: 'CustomerOrganizationList',
  mixins: [OrganizationListMixin],
  components: {
    'component-table': CustomerOrganizationTable,
    'common-filters': CustomerOrganizationCommonFilters
  },
  data () {
    return {
      CUSTOMER_ORG_ROUTES,
      title: this.$t('Customer Organizations'),
      rolePerm: ['organization_customerorganization_list'],
      actionEnablePermission: ['organization_customerorganization_enable'],
      actionDisablePermission: ['organization_customerorganization_disable'],
      orgType: this.$route.params.orgType,
      searchFields: [

        { key: 'filter__internal_code__icontains', placeholder: this.$t('Internal code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Business Name'), type: 'text', col: 6 },
        { key: 'filter__ssn_code__icontains', placeholder: this.$t('SSN'), type: 'text', col: 6 },
        { key: 'filter__vat_number__icontains', placeholder: this.$t('VAT number'), type: 'text', col: 6 },
        // { key: 'filter__sdi__icontains', placeholder: this.$t('SDI / CUU'), type: 'text', col: 6 },
        { key: 'filter__organization_groups__name__icontains', placeholder: this.$t('Category'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS
    }),
    showList: function () {
      return this.$route.name === this.CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_LIST
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS
    }),
    openEditForm (id) {
      this.$router.push({ name: this.CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_CREATE })
    },
    openDetailForm (id) {
      this.$router.push({ name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DETAIL, params: { id: id } })
    },
    getComponentList () {
      if (this.orgType) {
        return this.setFilters({ filter__organization_types__code: this.orgType })
      } else {
        return this.setFilters({})
      }
    }
  }
}
</script>
