<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CUSTOMER_ORG_TYPES } from './store'

import OrganizationTableMixin from '../../../components/organization/mixins/OrganizationTableMixin'
import { ROUTES as CUSTOMER_ORG_ROUTES } from '../customer-organization/router'
import { ORGANIZATION_CUSTOMER_CODE } from '../../../../../const'

export default {
  name: 'CustomerOrganizationTable',
  mixins: [OrganizationTableMixin],
  data () {
    return {
      showDocumentsButton: true,
      showDownloadDocumentsButton: true,
      showCompanyRegistryButton: true,
      showAgreementsButton: true,

      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'id',
          label: this.$t('Code'),
          type: 'number',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'id'
        },
        {
          key: 'internal_code',
          label: this.$t('Internal code'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'internal_code'
        },
        {
          key: 'name',
          label: this.$t('Business Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'vat_number',
          label: this.$t('VAT number'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'vat_number'
        },
        {
          key: 'ssn',
          label: this.$t('SSN'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'ssn'
        },
        {
          key: 'pec',
          label: this.$t('PEC'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'pec'
        },
        {
          key: 'sdi_code',
          label: this.$t('SDI / CUU'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'sdi_code'
        },
        {
          key: 'group_names',
          label: this.$t('Categories'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'code', 'name', 'vat_number', 'ssn', 'pec', 'sdi_code']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.selectAll,
      selectedList: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.GETTERS.selectedList
    })
  },
  methods: {
    ...mapMutations({
      setList: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setList,
      setSelectedList:
        CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setSelectedList,
      setSelectAll: CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...CUSTOMER_ORG_TYPES.GENERIC.organization.customerorganization.LIST.ACTIONS
    }),
    getDocumentsRouter (id, name) {
      return {
        name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_DOCUMENTS,
        params: { orgId: id, orgName: name, orgType: ORGANIZATION_CUSTOMER_CODE }
      }
    },
    getAgreementRouter (id, name) {
      return {
        name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_AGREEMENTS,
        params: { orgId: id, orgName: name, orgType: ORGANIZATION_CUSTOMER_CODE }
      }
    },
    getInspectionRouter (id, name) {
      return {
        name: CUSTOMER_ORG_ROUTES.CUSTOMER_ORGANIZATION_INSPECTIONS_LIST,
        params: { id: id, orgName: name, orgType: ORGANIZATION_CUSTOMER_CODE }
      }
    }
  }
}
</script>
